import Popup from './Popup';
import Button from '../button/Button';
import configs from '../../configs/configs';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

const gap = 184;

class PopupStatistic extends Popup {
  constructor(scene) {
    super(scene, 'popup-statistic', { title: 'My Statistics' });

    this.setVisible(false);

    this.backBtn = new Button(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-back-portfolio',
      'button-back-portfolio-pressed',
      () => {
        this.close();
        scene.popupPortfolio.open();
      },
      { sound: 'close' }
    );
    this.add(this.backBtn);

    this.rankText = scene.add
      .text(this.popup.x + 480, this.popup.y - 770, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.rankText);

    this.networthText = scene.add
      .text(this.rankText.x, this.rankText.y + gap - 15, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.networthText);

    this.networthPerDay = scene.add
      .text(this.rankText.x, this.networthText.y + 55, ``, {
        fontSize: fontSizes.small,
        color: colors.green,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5);
    this.add(this.networthPerDay);

    this.raidPtsText = scene.add
      .text(this.rankText.x, this.rankText.y + 2 * gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.raidPtsText);

    this.numberOfMachinesText = scene.add
      .text(this.rankText.x, this.popup.y - 100, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfMachinesText);

    this.numberOfBuildingsText = scene.add
      .text(this.rankText.x, this.numberOfMachinesText.y + gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfBuildingsText);

    this.numberOfWorkersText = scene.add
      .text(this.rankText.x, this.numberOfBuildingsText.y + gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfWorkersText);

    this.numberOfThugsText = scene.add
      .text(this.rankText.x, this.numberOfWorkersText.y + gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfThugsText);

    this.numberOfPistolsText = scene.add
      .text(this.rankText.x, this.numberOfThugsText.y + gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfPistolsText);

    this.numberOfShieldsText = scene.add
      .text(this.rankText.x, this.numberOfPistolsText.y + gap, ``, {
        fontSize: '60px',
        color: '#30030B',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(1, 0.5);
    this.add(this.numberOfShieldsText);

    scene.game.events.on(
      'update-statistic',
      ({
        rank,
        totalPlayers,
        numberOfWorkers,
        numberOfThugs,
        numberOfMachines,
        numberOfBuildings,
        raidPoint,
        numberOfPistols,
        numberOfShields,
      }) => {
        this.rankText.text = `${rank}/${formatter.format(totalPlayers)}`;
        this.raidPtsText.text = `${formatter.format(raidPoint)}`;
        this.numberOfMachinesText.text = `${formatter.format(numberOfMachines)} units`;
        this.numberOfBuildingsText.text = `${formatter.format(numberOfBuildings)} units`;
        this.numberOfWorkersText.text = `${formatter.format(numberOfWorkers)} units`;
        this.numberOfThugsText.text = `${formatter.format(numberOfThugs)} units`;
        this.numberOfPistolsText.text = `${formatter.format(numberOfPistols)} units`;
        this.numberOfShieldsText.text = `${formatter.format(numberOfShields)} units`;
      }
    );
    scene.game.events.on('update-networth', ({ networth, networthPerDay }) => {
      this.networthText.text = `${formatter.format(networth)}`;
      this.networthPerDay.text = `+${formatter.format(networthPerDay)} per day`;
    });

    scene.game.events.emit('request-networth');
    scene.game.events.emit('request-statistic');
  }
}

export default PopupStatistic;
